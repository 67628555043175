import { newAxiosIns } from '@/libs/axios'

const { VUE_APP_BASE_URL_NEW } = process.env

export function apiGetGroupContact(search, cursor, limit) {
  return newAxiosIns.get(`/komchat-api/api/v1/group-contact/list?cursor=${cursor}&limit=${limit}&search=${search}`)
}

export function apiImportContactFromExcel({ formData }) {
  return newAxiosIns.post('/komchat-api/api/v1/contact/import/excel', formData)
}

export function apiDownloadTemplateImportExcel() {
  return newAxiosIns.get('/komchat-api/api/v1/contact/import/excel/template', {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })
}

// google

export function apiSignInGoogle() {
  return newAxiosIns.post('/komchat-api/api/v1/auth/google/sign-in')
}

export function apiSignInGoogleVerify(code) {
  const params = {
    code,
  }
  return newAxiosIns.post('/komchat-api/api/v1/auth/google/sign-in/verify', params)
}

export function apiGoogleContactList(cursor, limit, token) {
  const params = {
    cursor,
    limit,
    token,
  }
  return newAxiosIns.get('/komchat-api/api/v1/contact/google', { params })
}

export function apiGoogleContactImport({ contact }) {
  const params = {
    contact,
  }
  return newAxiosIns.post('/komchat-api/api/v1/contact/google/import', params)
}
